import "./style.css";
import { GridDisplay } from "../../components";
import { default as sponsors } from "./sponsors.json";
import React, { useState } from "react";

export default function SponsorsPage(): JSX.Element {
  const [sponsorsData] = useState<any[]>(sponsors.data);

  function buildSponsor(sponsorInfo: any): JSX.Element {
    return (
      <div className="sponsor-image-container">
        <img src={sponsorInfo.link} alt="Sponsor-Image" />
      </div>
    );
  }

  function displayGridLevel(
    perRow: number,
    classname: string,
    level: string
  ): JSX.Element {
    return (
      <div className={classname}>
        <GridDisplay
          documents={sponsorsData.filter((sponsor) => sponsor.group === level)}
          size={perRow}
          viewElement={buildSponsor}
        />
      </div>
    );
  }

  function SponsorLevel(
    title: string,
    level: string,
    rgb: string
  ): JSX.Element {
    return (
      <>
        <div className="sponsors-page-level" style={{ backgroundColor: rgb }}>
          {title}
        </div>
        {displayGridLevel(4, "sponsors-page-content-wide", level)}
        {displayGridLevel(2, "sponsors-page-content-small", level)}
      </>
    );
  }

  return (
    <div className="sponsors-page-container">
      <h2>Thanks to our Sponsors</h2>
      {SponsorLevel("Platinum Sponsors", "platinum", "#E5E4E2")}
      {SponsorLevel("Gold Sponsors", "gold", "#FFD700")}
      {SponsorLevel("Silver Sponsors", "silver", "#C0C0C0")}
      {SponsorLevel("Community Sponsors", "community", "#cc9933")}
      {SponsorLevel("Lifetime Sponsors", "lifetime", "#90EE90")}
    </div>
  );
}
