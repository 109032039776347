import React, { useEffect, useState } from "react";
import "./style.css";

interface GridDiplayProps {
  documents: any[];
  size: number;
  viewElement: (item: string) => JSX.Element;
}

export default function GridDisplay({
  documents,
  size,
  viewElement,
}: GridDiplayProps): JSX.Element {
  const [documentSize] = useState(documents.length);
  const [documentSlices, setDocumentSlices] = useState<any[]>([]);

  useEffect(() => {
    let pointer = 0;
    const slices: any[] = [];
    while (pointer < documentSize) {
      slices.push(documents.slice(pointer, pointer + size));
      pointer += size;
    }
    setDocumentSlices(slices);
  }, []);

  function buildRow(slice: any[]): JSX.Element {
    return (
      <div className="build-row-display">
        {slice.map((item) => viewElement(item))}
      </div>
    );
  }

  return (
    <div className="grid-container">
      {documentSlices.map((slice) => buildRow(slice))}
    </div>
  );
}
