import "./style.css";
import React from "react";
import { Footer, NavBar } from "../components";
import { createBrowserHistory } from "history";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import HomePage from "./HomePage";
import ForumPage from "./ForumPage";
import AboutPage from "./AboutPage";
import DonatePage from "./DonatePage";
import RegisterPage from "./RegisterPage";
import ScholarshipPage from "./ScholarshipPage";
import SponsorsPage from "./SponsorsPage";

import { Fragment, useState } from "react";

import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import Button from "@mui/material/Button";
import PrizesPages from "./PrizesPages";

const history = createBrowserHistory();

const style = {
  position: "fixed",
  left: "96%",
  width: "4%",
  bottom: "40px",
  height: "20px",
  size: "3rem",
  p: 2,
  cursor: "pointer",
  color: "black",
};

export default function Pages(): JSX.Element {
  const ScrollButton = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
        setVisible(true);
      } else if (scrolled <= 300) {
        setVisible(false);
      }
    };

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
        /* you can also use 'auto' behavior
           in place of 'smooth' */
      });
    };

    window.addEventListener("scroll", toggleVisible);

    return (
      <Button sx={style}>
        <ArrowUpwardOutlinedIcon
          viewBox="true"
          onClick={scrollToTop}
          style={{ display: visible ? "inline" : "none" }}
        />
      </Button>
    );
  };

  return (
    <div className="page-container">
      <Fragment>
        <Router>
          <div className="page-header">
            Judge Charles V. Johnson Youth and Law Forum
          </div>
          <NavBar />
          <div className="page-content">
            <Routes>
              <Route path="/home" element={<HomePage />} />
              <Route path="/forum" element={<ForumPage />} />
              <Route path="/forum/prizes" element={<PrizesPages />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/sponsors" element={<SponsorsPage />} />
              {/* <Route path="/donate" element={<DonatePage />} /> */}
              <Route path="/scholarship" element={<ScholarshipPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="" element={<Navigate to="/home" />} />
            </Routes>
          </div>
          <Footer />
        </Router>
        <ScrollButton />
      </Fragment>
    </div>
  );
}
