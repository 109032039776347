import "./style.css";
import React from "react";

export default function Footer(): JSX.Element {
  return (
    <div className="footer-container">
      <div className="footer-column-element">
        <h5>Contact Information</h5>
        <p>
          <a href="tel:(206) 257-5572">(206) 257-5572</a>
        </p>
        <p>
          <a href="mailto:youthandlawforum@gmail.com">
            youthandlawforum@gmail.com
          </a>
        </p>
        <p>MLK FAME Community Center,</p>
        <p> 3201 E Republican Street, Seattle,</p>
        <p>WA 98112</p>
      </div>
      <div>
        <h5>Connect on Social Media</h5>
        <div className="footer-column-element-media">
          <div>
            <a href="https://www.facebook.com/youthandlaw/" target="_blank">
              <img
                src="https://raw.githubusercontent.com/YouthandLaw/YLFContent/master/faceIcon.png"
                alt=""
              />
            </a>
          </div>
          <div>
            <a
              href="https://instagram.com/youthandlaw?igshid=YmMyMTA2M2Y="
              target="_blank"
            >
              <img
                src="https://raw.githubusercontent.com/YouthandLaw/YLFContent/master/InstaIcon.png"
                alt=""
              />
            </a>
          </div>

          <div>
            <a href="https://twitter.com/youthlawforum?lang=en" target="_blank">
              <img
                src="https://raw.githubusercontent.com/YouthandLaw/YLFContent/master/twittweIcon.png"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-column-element-right">
        All Right Reserved • 2023
      </div>
    </div>
  );
}
