import "./style.css";
import React, { useState } from "react";

interface ScheduleCardProps {
  imageLocation: string;
  time: string;
  theme: string;
  location?: string;
  duration?: string;
  summary: string;
}

export default function ScheduleCard({
  imageLocation,
  time,
  theme,
  location = "",
  duration = "",
  summary,
}: ScheduleCardProps): JSX.Element {
  const [viewMore, setViewMore] = useState(false);

  return (
    <div className="schedule-card-container">
      {/* {imageLocation && (
        <div>
          <img src={imageLocation} alt="image-for-the-activity" />
        </div>
      )} */}
      <div className="schedule-card-info">
        <h4>{theme}</h4>

        {location !== "" && <h5>{location}</h5>}
        {time !== "" && <h5>{time}</h5>}
        {duration !== "" && <h5>{duration}</h5>}
        <span>{summary}</span>
      </div>
    </div>
  );
}
