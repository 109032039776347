import { GridDisplay, ScheduleCard, ViewModal } from "../../components";
import React, { useEffect, useState } from "react";
import "./style.css";
import { default as peopleBio } from "./peopleBio.json";
import { default as scheduleInfo } from "./schedule.json";
import { default as documentFolder } from "./documents.json";
import { useCountdown } from "../../hooks/useCountdown";
import Collapsible from "react-collapsible";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export function CollapsibleTrigger(
  titleOnOpen: string,
  titleOnClose: string,
  isOpen: boolean
): JSX.Element {
  return (
    <div className="collapsible-trigger">
      <h3>{isOpen ? titleOnOpen : titleOnClose}</h3>
      {isOpen ? (
        <ExpandLessIcon fontSize="large" />
      ) : (
        <ExpandMoreIcon fontSize="large" />
      )}
    </div>
  );
}

export default function ForumPage(): JSX.Element {
  const [showSchedule] = useState(true);
  const [showTimer] = useState(false);
  const [viewSchedule, setViewSchedule] = useState(false);
  const [viewMap, setViewMap] = useState(false);
  const [viewThisYear, setViewThisYear] = useState(false);
  const [viewPreviousYear, setViewPreviousYear] = useState(false);
  const [viewDocuments, setViewDocument] = useState(false);
  const [people] = useState(peopleBio.data);
  const [schedule] = useState(scheduleInfo.data);
  const [documents] = useState(documentFolder.data);
  const forumDate = new Date("10/21/2023").getTime();
  const NOW_IN_MS = new Date().getTime();
  const DIFFERENCE_DAYS_IN_MS = forumDate - NOW_IN_MS;

  const dateTimeAfterThreeDays = NOW_IN_MS + DIFFERENCE_DAYS_IN_MS;

  function viewPeopleModal(bio: any): JSX.Element {
    return (
      <ViewModal
        imageSource={bio.link}
        description={bio.bio}
        title={bio.name}
      />
    );
  }

  const DateTimeDisplay = ({
    value,
    type,
    isDanger,
  }: {
    value: number;
    type: any;
    isDanger: boolean;
  }) => {
    return (
      <div className={isDanger ? "countdown danger" : "countdown"}>
        <p>{value}</p>
        <span>{type}</span>
      </div>
    );
  };

  const ExpiredNotice = () => {
    return (
      <div className="expired-notice">
        <span>Expired!!!</span>
        <p>Please select a future date and time.</p>
      </div>
    );
  };

  const ShowCounter = ({
    days,
    hours,
    minutes,
    seconds,
  }: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }) => {
    return (
      <div className="show-counter">
        <a
          href=""
          target="_blank"
          rel="noopener noreferrer"
          className="countdown-link"
        >
          <DateTimeDisplay value={days} type={"Days"} isDanger={days <= 3} />
          <p>:</p>
          <DateTimeDisplay value={hours} type={"Hours"} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={minutes} type={"Mins"} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={seconds} type={"Seconds"} isDanger={false} />
        </a>
      </div>
    );
  };

  const CountdownTimer = ({ targetDate }: { targetDate: number }) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
      // setShowTimer(false);
      return <></>;
    } else {
      return (
        <ShowCounter
          days={days}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
        />
      );
    }
  };

  function displaySpeaker(perRow: number, className: string): JSX.Element {
    return (
      <div className={className}>
        <Collapsible
          trigger={CollapsibleTrigger(
            "This Year Guests, Leaders and Speakers",
            "This Year Guests, Leaders and Speakers",
            viewThisYear
          )}
          open={viewThisYear}
          onTriggerOpening={() => setViewThisYear(true)}
          onTriggerClosing={() => setViewThisYear(false)}
        >
          {people.filter((person) => person.group === "current").length !==
            0 && (
            <>
              <h3>This Year Guests, Leaders and Speakers</h3>
              <GridDisplay
                documents={people.filter(
                  (person) => person.group === "current"
                )}
                size={perRow}
                viewElement={viewPeopleModal}
              />
            </>
          )}
        </Collapsible>
        <Collapsible
          trigger={CollapsibleTrigger(
            "Previous Years Guests, Leaders and Speakers",
            "Previous Years Guests, Leaders and Speakers",
            viewPreviousYear
          )}
          open={viewPreviousYear}
          onTriggerOpening={() => setViewPreviousYear(true)}
          onTriggerClosing={() => setViewPreviousYear(false)}
        >
          <h3> Previous Years Guests, Leaders and Speakers</h3>
          <GridDisplay
            documents={people.filter((person) => person.group === "speakers")}
            size={perRow}
            viewElement={viewPeopleModal}
          />
        </Collapsible>
      </div>
    );
  }

  function downloadDocument(): JSX.Element {
    return (
      <Collapsible
        trigger={CollapsibleTrigger(
          "Forum Materials",
          "Forum Materials",
          viewDocuments
        )}
        open={viewDocuments}
        onTriggerOpening={() => setViewDocument(true)}
        onTriggerClosing={() => setViewDocument(false)}
      >
        <div className="download-document-wide">
          <GridDisplay
            documents={documents}
            size={4}
            viewElement={(file: any) => {
              return (
                <div className="download-document">
                  <a href={file.downloadLink} target="_blank">
                    <img src={file.imageLink} alt={file.title} />
                  </a>
                  <h4 style={{ padding: "15px" }}>{file.title}</h4>
                </div>
              );
            }}
          />
        </div>
        <div className="download-document-small">
          <GridDisplay
            documents={documents}
            size={2}
            viewElement={(file: any) => {
              return (
                <div className="download-document">
                  <a href={file.downloadLink}>
                    <img src={file.imageLink} alt={file.title} />
                  </a>
                  <h4 style={{ padding: "15px" }}>{file.title}</h4>
                </div>
              );
            }}
          />
        </div>
      </Collapsible>
    );
  }

  return (
    <div className="forum-page-container">
      <div className="page-title">
        Knowledge is Power: The Hard Truth About Artifical Intelligence
      </div>
      <h3>November 2, 2024</h3>
      <h3>9:00 AM - 4:00 PM</h3>

      {/* <div className="forum-register-here">
        <a href="/register" rel="noopener" style={{ textDecoration: "none" }}>
          <h2 style={{ color: "black", margin: "0" }}>Register Here</h2>
        </a>
      </div> */}

      {showTimer && (
        <div>
          <h4>Countdown</h4>
          <CountdownTimer targetDate={dateTimeAfterThreeDays} />
        </div>
      )}
      <Collapsible
        trigger={CollapsibleTrigger(
          "Event Layout Map",
          "Event Layout Map",
          viewMap
        )}
        open={viewMap}
        onTriggerOpening={() => setViewMap(true)}
        onTriggerClosing={() => setViewMap(false)}
      >
        <img
          className="layout-map"
          src="https://raw.githubusercontent.com/YouthandLaw/YLFContent/master/Prizes2022/sample-event-layout-map.png"
          alt="Layout of the Community Center"
        />
      </Collapsible>
      <Collapsible
        trigger={CollapsibleTrigger(
          "Hide Schedule",
          "Expand to View Schedule",
          viewSchedule
        )}
        open={viewSchedule}
        onTriggerOpening={() => setViewSchedule(true)}
        onTriggerClosing={() => setViewSchedule(false)}
      >
        {/* <a href="https://raw.githubusercontent.com/YouthandLaw/YLFContent/master/banners/YLF%20program.pdf">
          <p style={{ padding: "6px" }}>
            Download a copy of the Program Booklet
          </p>
        </a> */}
        <div className="card-display">
          {showSchedule &&
            schedule.map((item: any) => {
              return (
                <ScheduleCard
                  imageLocation={item.link}
                  theme={item.theme}
                  time={item.time}
                  location={item.location}
                  duration={item.duration}
                  summary={item.summary}
                />
              );
            })}
        </div>
      </Collapsible>
      {displaySpeaker(4, "speakers-display-wide")}
      {displaySpeaker(1, "speakers-display-small")}
      {downloadDocument()}
    </div>
  );
}
