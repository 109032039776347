import "./style.css";
import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";

// credits:
// https://www.newline.co/@andreeamaco/react-carousel-building-the-component-from-scratch-vs-using-a-library--7da468d4

export const SlideImage = styled.img`
  width: 100%;
  height: 350px;
  object-fit: cover;
`;

export const StyledSlider = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface SliderProps {
  slides: any[];
}

export default function Slider({ slides }: SliderProps): JSX.Element {
  const [current, setCurrent] = useState(0);
  const length = slides.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  // useEffect(() => {
  //   setInterval(
  //     () => setCurrent(current === length - 1 ? 0 : current + 1),
  //     7000
  //   );
  // }, [current]);

  return (
    <StyledSlider>
      <div>
        <FaChevronLeft className="left-arrow" onClick={prevSlide} />
        <FaChevronRight className="right-arrow" onClick={nextSlide} />
        {slides.map((slide, index) => {
          return (
            <div key={index}>
              {index === current && (
                <SlideImage src={slide.image} alt={slide.title} />
              )}
            </div>
          );
        })}
      </div>
    </StyledSlider>
  );
}
