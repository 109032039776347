import "./style.css";
import React, { useState } from "react";
import { default as Prizes } from "./Prizes.json";

export default function PrizesPages(): JSX.Element {
  const [prizes] = useState(Prizes.data);

  return (
    <div className="prizes-container">
      <h2>Prize List for 2023 Forum</h2>
      {prizes.map((prize: any) => {
        return (
          <div className="prize-item">
            <img src={prize.image} />
            <h3>{prize.title}</h3>
            <br />
          </div>
        );
      })}
      <a href="/forum">{`<<< Back to Forum Page`}</a>
    </div>
  );
}
