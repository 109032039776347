import { Button } from "@mui/material";
import "./style.css";
import React, { useEffect } from "react";

export default function RegisterPage(): JSX.Element {

  
  return (
    <div className="register-page">
      <div>
        <p>
          Please take some time to register to the Youth and Law Forum for
          <br /> the current year 2024.
          <br />
          We encorage you to talk to your entourage and have friends and family
          register as soon as possible
        </p>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSfSZ3tTQeO6-XnGJEEP_E3kmRmgf3k9tJq8SIuTptboAIWOMw/viewform?usp=sf_link"
          target="_blank"
        >
          <div className="register-button">
            <h3>REGISTER TO THE FORUM</h3>
          </div>
        </a>
        <div className="register-form">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfSZ3tTQeO6-XnGJEEP_E3kmRmgf3k9tJq8SIuTptboAIWOMw/viewform?usp=sf_link">
            Loading…
          </iframe>
        </div>
      </div>
      <br />
      <div className="volunteer-button">
        {/* <p>
          If you are interested in volunteering with the Youth and Law forum,
          <br /> contrinbute and learn more about the organization of this
          event,
          <br />
          Please take some time to fill out our volunteer form and we will make
          <br /> sure to reach out to you to get you onboard.
          <br />
          Thank you
        </p> */}
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSd6w4vz_hhyaCci4tnmTZnKauSruYEGEY0BP5elxX6S9Q8nig/viewform?usp=sf_link"
          target="_blank"
        >
          <div className="register-button">
            <h3>SIGN UP TO VOLUNTEER</h3>
          </div>
        </a>
        {/* <div className="register-form">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSd6w4vz_hhyaCci4tnmTZnKauSruYEGEY0BP5elxX6S9Q8nig/viewform?embedded=true"
            width="600"
            height="600"
          >
            Loading…
          </iframe>
        </div> */}
      </div>
    </div>
  );
}
