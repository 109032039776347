import "./style.css";
import { default as newsInfo } from "./newsinfo.json";
import React, { useState } from "react";
import GridDisplay from "../GridDisplay";

export default function News(): JSX.Element {
  const [news] = useState<any[]>(newsInfo.data);

  function newsCard(newsdata: any): JSX.Element {
    return (
      <a href={newsdata.link} target="_blank">
        <div className="newsCard-container">
          <img src={newsdata.picture} alt="image-for-the-activity" />
          <p>{newsdata.message}</p>
        </div>
      </a>
    );
  }

  return (
    <>
      <div className="news-container-wide">
        <GridDisplay documents={news} size={5} viewElement={newsCard} />
      </div>
      <div className="news-container-small">
        <GridDisplay documents={news} size={2} viewElement={newsCard} />
      </div>
    </>
  );
}
