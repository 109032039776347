import "./style.css";
import React from "react";

interface PeopleCardProps {
  imageLocation: string;
  message: string;
  name: string;
}

export default function PeopleCard({
  imageLocation,
  message,
  name,
}: PeopleCardProps): JSX.Element {
  return (
    <div className="people-card-container">
      <div className="people-card-image">
        <img src={imageLocation} alt="people-card-image" />
      </div>
      <div className="people-info">
        <h3>{name}</h3>
        <p>{message}</p>
      </div>
    </div>
  );
}
