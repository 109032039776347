import "./style.css";
import React from "react";

export default function ScholarshipPage(): JSX.Element {
  return (
    <div className="scholarship-container">
      <p>
        <br />
        <br />
        <b>About the scholarship</b>
        <br />
        The annual Judge Charles V. Johnson Youth and Law Forum now has a
        scholarship for graduating high school seniors who are enrolled to start
        in a technical school, a two year program, or a four year program, with
        an emphasis on law, social justice, justice, business, or some related
        field.
        <br />
        <br />
        {/* <b>Deadline</b>
        <br />
        The deadline for the scholarship is <b>Friday, May 27, 2022</b>.
        Applicants must mail a completed paper copy of the application to First
        AME Church Scholarship Foundation, c/o First AME Church, 1522 14th
        Avenue, Seattle, WA 98122. The scholarship must be received or
        postmarked by Friday, May 27.
        <br /> */}
        <br />
        <b>Questions</b>
        <br />
        If you are interested in sponsoring scholarships, please contact Dr.
        Margaret Spearmon at <a href="tel:2066693459">(206) 669-3459</a> or via
        email at <a href="mailto:spearmon@uw.edu ">spearmon@uw.edu</a> or,
        Christopher Sanders at <a href="tel:2066600608">(206) 660-0608</a> or
        via email at{" "}
        <a href="mailto:chris.michael.sanders@gmail.com">
          chris.michael.sanders@gmail.com
        </a>
        .
        <br /> <br />
      </p>
      {/* <h4>YLF Scholarship Packet</h4>
      <iframe
        src="https://drive.google.com/file/d/15yNDqUOOXLvM6DEwn_RiQZ1S7FzYa-hU/preview"
        width="80%"
        height="680"
        allow="autoplay"
      ></iframe> */}
    </div>
  );
}
