import "./style.css";
import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";

export default function NavBar(): JSX.Element {
  const [url, setUrl] = useState(window.location.pathname);

  console.log(url);

  function navigatorOption(route: string, value: string): JSX.Element {
    return (
      <Link
        className={
          url === `${route}` ? "nav-bar-selector-url" : "nav-bar-selector"
        }
        to={`${route}`}
        onClick={() => setUrl(`${route}`)}
      >
        {value}
      </Link>
    );
  }

  return (
    <div className="nav-bar-container">
      <div>
        <Link
          className="nav-bar-title"
          to="/home"
          onClick={() => setUrl("/home")}
        >
          <h3>Youth & Law Forum</h3>
        </Link>
      </div>

      <div className="menu-option">
        <Link
          className={
            url === "/home" || url === "/"
              ? "nav-bar-selector-url"
              : "nav-bar-selector"
          }
          to="/home"
          onClick={() => setUrl("/home")}
        >
          Home
        </Link>
        {navigatorOption("/forum", "Forum")}
        {navigatorOption("/sponsors", "Sponsors")}
        {navigatorOption("/scholarship", "Scholarship")}
        {navigatorOption("/about", "About")}
        <Link
          className={
            url === "/register" ? "nav-bar-register-url" : "nav-bar-register"
          }
          to="/register"
          onClick={() => setUrl("/register")}
        >
          Register
        </Link>
        {/* <Link
          className={
            url === "/donate" ? "nav-bar-donate-url" : "nav-bar-donate"
          }
          to="/donate"
          onClick={() => setUrl("/donate")}
        >
          Donate
        </Link> */}
      </div>
    </div>
  );
}
